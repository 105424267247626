// Show a banner asking the user to consent to cookies, unless they have already done that before.
$(function() {
  if (window.localStorage.consentedToCookies != "true") {
    $("#cookie-banner").removeClass("d-none");
    $("#cookie-banner-accept").click(() => {
      window.localStorage.consentedToCookies = true;
      $("#cookie-banner").addClass("d-none");
    });
    $("#cookie-banner-close").click(() => {
      $("#cookie-banner").addClass("d-none");
    });
  }
});
