// Ajax Forms 2.0.0

// Convert a form into an AJAX form.
function ajaxForm(params) {
  var form = $(params.form), button = $(params.button);
  if (form.length == 0) { console.warn("Form not found: " + params.form); return; }
  if (button.length == 0) {
    button = form.find("button[type='submit']");
  } else {
    button.click(function() { if (form[0].reportValidity()) { form.submit(); } });
  }
  if (button.length == 0) { console.warn("Button not found: " + params.button); return; }
  params.url ||= form.prop("action");
  form.submit(function(evt) {
    evt.preventDefault();
    ajaxSubmit(Object.assign({
      data: () => $(params.form).serialize()
    }, params));
  });
}

// Submit AJAX data and use a button provided in the params to provide visual feedback of the process
function ajaxSubmit(params) {
  $.ajax(Object.assign({}, params, {
    url: evaluate(params.url),
    type: params.type || "POST",
    data: evaluate(params.data),
    success: function(res) {
      params.limited ? setButtonState($(params.button), "finished") : setButtonState($(params.button), "normal");
      (params.success || (function(res) { showSuccess(successMessageFor(res), params.name); }))(res);
    },
    error: function(xhr) {
      setButtonState($(params.button), "normal");
      (params.error || (function(xhr) { showError(errorMessageFor(xhr), params.name); }))(xhr);
    },
    beforeSend: function(xhr) {
      if (params.validates && !params.validates()) return false;
      if (params.beforeSend) params.beforeSend(xhr);
      var button = $(params.button);
      if (button.prop("disabled")) { return false; }
      setButtonState(button, "waiting");
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    }
  }));
}

// Default handler for successful AJAX requests. Given a JSON response from the server, get a success message.
function successMessageFor(res) {
  return res.message;
}

// Default handler for failed AJAX requests. Given the XMLHttpRequest response, determine the most appropriate error message to show to the user.
function errorMessageFor(xhr) {
  if (xhr.responseText) {
    try {
      return JSON.parse(xhr.responseText).message || xhr.responseText;
    } catch (ex) {
      return messageForStatus(xhr.status);
    }
  } else {
    return messageForStatus(xhr.status);
  }
}

function messageForStatus(status) {
  switch(status) {
    case 0: return "Failed to contact the server. Please check your internet connection and try again.";
    case 401: return "You are not authorized to perform this action. You might have been logged out. Please refresh this page.";
    case 403: return "You are not authorized to perform this action.";
    case 404: return "The requested resource was not found.";
    case 413: return "Your request was rejected because it is too large.";
    case 429: return "You must wait a short while before trying again.";
    case 500: return "Sorry, there was a problem processing your request. Please try again in a while or contact us if the problem persists.";
    case 503: return "The server is currently unavailable due to maintenance. Please try again in a while.";
    default: return "Sorry, an unexpected problem occurred. Please try again in a while or contact us if the problem persists. Code: " + status;
  }
}

function setButtonState(button, state) {
  switch (state) {
    case "normal":
      button.removeClass("ajax-waiting");
      button.removeClass("ajax-finished");
      button.html(button.prop('oldHtml'));
      button.prop("disabled", false);
      break;
    case "waiting":
      button.prop("disabled", true);
      button.addClass("ajax-waiting");
      button.removeClass("ajax-finished");
      button.prop('oldHtml', button.html());
      button.html("<i class='fa fa-refresh fa-spin'></i> Submitting");
      break;
    case "finished":
      button.removeClass("ajax-waiting");
      button.addClass("ajax-finished");
      button.html("<i class='fa fa-check'></i> Done!");
      button.prop("disabled", true);
      break;
    default:
      console.error("Invalid state for button: " + state);
  }
}

function evaluate(variable) {
  return typeof (variable) == "function" ? variable() : variable;
}

// This is like an Ajax Form but simpler. Instead of sending form data, it's just a bare button that sends arbitrary data.
function ajaxButton(params) {
  var btn = $(params.button);
  if (btn.length < 1) { console.warn("Button not found: " + params.button); return; }
  btn.click(function(evt){
    evt.preventDefault();
    ajaxSubmit(params);
  });
}

export { ajaxForm, ajaxButton, errorMessageFor, messageForStatus, setButtonState }
